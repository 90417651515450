<template>
  <table class="table-auto">
    <thead>
      <tr>
        <th>Nome</th>
        <th>Inizio</th>
        <th>Fine</th>
        <th></th>
        <th></th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="(session, i) in sessions" :key="session.id">
        <td>{{ session.name }}</td>
        <td>{{ dateTime(session.date) }}</td>
        <td>{{ dateTime(session.dateEnd) }}</td>
        <td>
          <router-link
            :to="{
              name: 'Session',
              params: {
                id: session.id,
                name: session.name,
                date: session.date,
                dateEnd: session.dateEnd,
                code: session.code,
              },
            }"
            class="text-slate-500 hover:text-sky-500 transition"
          >
            <EyeIcon class="w-5 h-5" />
          </router-link>
        </td>
        <td>
          <router-link
            :to="{
              name: 'SessionEdit',
              params: { id: session.id, name: session.name },
            }"
            class="text-slate-500 hover:text-sky-500 transition"
          >
            <PencilIcon class="w-5 h-5" />
          </router-link>
        </td>
        <td>
          <TrashIcon
            @click="$emit('delete', session, i)"
            class="delete-session w-5 h-5 text-slate-500 hover:text-sky-500 transition"
          ></TrashIcon>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import moment from "moment";
moment.locale("it");

import { EyeIcon, PencilIcon, TrashIcon } from "@heroicons/vue/solid";

export default {
  name: "SessionTable",
  components: { EyeIcon, PencilIcon, TrashIcon },
  props: ["sessions"],
  emits: ["delete"],
  methods: {
    dateTime(value) {
      return moment(value).format("DD MMMM YYYY HH:mm");
    },
  }
};
</script>

<style scoped>
table {
  border-spacing: 0px;
  border-collapse: collapse;
  width: 100%;
  max-width: 100%;
  margin-bottom: 15px;
  background-color: transparent; /* Change the background-color of table here */
  text-align: left; /* Change the text-alignment of table here */
}

th {
  font-weight: bold;
  border: 1px solid #cccccc; /* Change the border-color of heading here */
  padding: 8px;
}

td {
  border: 1px solid #cccccc; /* Change the border-color of cells here */
  padding: 8px;
}
</style>
