<template>
  <div>
    <Navbar />

    <div class="px-4 lg:px-2 py-8 2xl:py-10 md:px-12 flex-1">
      <div
        class="flex justify-between mb-4 space-x-4 lg:flex-row lg:items-center"
      >
        <div class="flex items-center space-x-5">
          <router-link :to="{ name: 'SessionCreate' }" class="btn btn--red"
            >Crea</router-link
          >
        </div>
      </div>

      <div>
        <h2 class="text-2xl 2xl:text-4xl font-semibold">Serate future</h2>
        <SessionTable
          v-if="!loading && futureSessions.length > 0"
          :sessions="futureSessions"
          @delete="(s) => deleteSession(s.id)"
        ></SessionTable>
        <p v-if="!loading && futureSessions.length === 0">
          Non ci sono serate future
        </p>
      </div>

      <div>
        <h2 class="text-2xl 2xl:text-4xl font-semibold">Serate passate</h2>
        <SessionTable
          v-if="!loading && pastSessions.length > 0"
          :sessions="pastSessions"
          @delete="(s) => deleteSession(s.id)"
        ></SessionTable>
        <p v-if="!loading && pastSessions.length === 0">
          Non ci sono serate passate
        </p>
        <button
          v-if="pastSessions.length === limit * page"
          class="btn"
          @click="loadMore"
        >
          {{ loadingMore ? "Caricamento" : "Mostra più risultati" }}
        </button>
      </div>
    </div>

    <Footer />
  </div>
</template>

<script>
import { getClient } from "@/includes/api";

import Footer from "@/components/Footer.vue";
import Navbar from "@/components/Navbar.vue";
import SessionTable from "@/components/SessionTable.vue";

export default {
  components: {
    Footer,
    Navbar,
    SessionTable,
  },
  props: ["id", "name"],
  data() {
    return {
      now: new Date(),
      limit: 20,
      page: 1,
      futureSessions: [],
      pastSessions: [],
      loading: false,
      loadingMore: false,
      sessions: [],
      error: null,
      results: [],
      toggleSearch: false,
    };
  },
  mounted() {
    this.loadSessions();
  },
  methods: {
    async loadSessions() {
      try {
        this.loading = true;
        this.page = 1;
        let apiClient = getClient();
        [this.futureSessions, this.pastSessions] = await Promise.all([
          apiClient.getAllSessions({ to: this.now }),
          apiClient.getAllSessions({
            limit: this.limit,
            offset: 0,
            from: this.now,
          }),
        ]);
      } catch (err) {
        console.error(err);
      } finally {
        this.loading = false;
      }
    },
    async loadMore() {
      if (this.loadingMore) {
        return;
      }

      try {
        this.loadingMore = true;
        this.page++;
        let apiClient = getClient();
        const sessions = await apiClient.getAllSessions({
          limit: this.limit,
          offset: this.limit * this.page,
          from: this.now,
        });
        this.pastSessions.push(...sessions);
      } catch (err) {
        console.error(err);
      } finally {
        this.loadingMore = false;
      }
    },
    async deleteSession(id) {
      try {
        if (window.confirm(`Sei sicuro di voler cancellare la serata?`)) {
          const client = getClient();
          await client.deleteSession(id);
          await this.loadSessions();
        }
      } catch (err) {
        console.error(err);
        this.error = "Impossibile cancellare la serata";
      }
    },
  },
  computed: {
    filteredSessions() {
      return this.sessions.filter((session) => session.code === this.code);
    },
  },
};
</script>
